import React, {useState, useContext} from 'react';
import fetchData from '../../global/FetchData';
import CustomTable from '../../components/CustomTable';
import { AppContext } from '../../global/context' 
import {CircleFill, Check2All, XCircle} from 'react-bootstrap-icons';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


function Cardapio({setLoading, setTitle}) {    
    
    const MySwal = withReactContent(Swal);  
    const [tableData, setTableData] = useState([]); 
    // const [procardId, setProcardId] = useState(0);       
    const {getActiveProcard, token, refresh, setRefresh } = useContext(AppContext); 

    setTitle('Cardápio/Produtos');


    React.useEffect(() => {
      
      const loadTable = async(procard_id) => {      
        setLoading(true);      
        const dados = {procard_id}          
        const retorno = await fetchData.getCardapio(dados, token);
  
        //-- Sessão expirada
        if(retorno.code === 401){
          setLoading(false);
          sessionStorage.clear();    
          window.location.href = '/';      
        }
        
        setTableData(retorno.items)
        setLoading(false);
      }   

      const selected = getActiveProcard();
      loadTable(selected.id);        
    }, [refresh, getActiveProcard, setLoading, token]);

    

    const handleLineClick = async(row) => {
      //console.log(row)
      setLoading(true); 
      
      const selected = getActiveProcard();

      const data = {
        "id": row.proibido_id,
        "procard_id": selected.id,
        "produto_id": row.id,
        "permitido": (row.permitido === '1') ? '0' : '1'
      }
        
      const retorno = await fetchData.saveProcardProdutosProibidos(data, token);
      if (retorno.data.status === "error") {
          MySwal.fire({
              title: "Oops!",
              html: retorno.data.message,
              icon: "error",
          });
          setLoading(false); 
          return;
      }
      
      //loadTable(selected.id)
      setRefresh(!refresh)
      setLoading(false); 
      //setTableData({...tableData, ['king_pass']: ""});        
      MySwal.fire({
        title: "Tudo certo!",
        html: retorno.data.message,
        icon: "success",
    });
    }
    
    const processaTipo = (nutri_id) => {        
        const colors = {
            1: 'rgba(75, 192, 192, 0.2)', //green
            2: 'rgba(54, 162, 235, 0.2)', //blue
            3: 'rgba(153, 102, 255, 0.2)', //purple
            4: 'rgba(255, 99, 132, 0.2)', //red
        }
        
        switch(nutri_id){                        
            case '2': return <CircleFill style={{color: colors[2]}}/>;
            case '3': return <CircleFill style={{color: colors[3]}}/>;
            case '4': return <CircleFill style={{color: colors[4]}}/>;
            default: return <CircleFill style={{color: colors[1]}}/>;
        }
    }

    const processaPermissao = (proibido_id, permitido) => {        
      
      if(proibido_id > 0 && permitido === "0"){
        const color = 'rgba(191, 0, 41)';
        return <XCircle style={{color: color}}/>
      }
      else{
        const color = 'rgba(17, 106, 106)';
        return <Check2All style={{color: color}}/> 
      }
  }

    const processaCat = (categoria) => {
        return (categoria && categoria !== '' ) ? categoria : 'GERAL';        
    }

    const columns = [
        {  name: 'CÓDIGO', selector: row => row.codigo, sortable: true, hide: 'sm', maxWidth: '50px'},        
        {  name: 'DESCRIÇÃO', selector: row => row.descricao, sortable: true, minWidth: '310px', maxWidth: '70%'},
        {  name: 'CATEGORIA/GRUPO', selector: row => processaCat(row.categoria), sortable: true, hide: 'sm'},                
        {  name: 'VALOR', selector: row => (parseFloat(row.valor)).toFixed(2), sortable: true, width: '90px'},        
        {  name: 'PERMITIDO', selector: row => processaPermissao(row.proibido_id, row.permitido), sortable: true},        
        {  name: 'UN.', selector: row => row.unidade, sortable: true, hide: 'sm', maxWidth: '50px'},        
        {  name: 'TIPO', selector: row => processaTipo(row.nutri_id), sortable: false, maxWidth: '30px', hide: 'sm'},  
    ];

    return(           
      <div className="container-fluid">   
        <div className="m-0 m-sm-4">   
            <div class="alert alert-info" role="alert">
              Clique na linha do item para habilitar ou desabilitar o alimento para o aluno/usuário. Alimentos desabilitados
              não podem ser consumidos na cantina/restaurante por este usuário.
            </div>

            <CustomTable 
              title="Lista de Itens do Cardápio"               
              columns={columns} 
              data={tableData}  
              handleClick={handleLineClick}   
              pointerOnHover={true} 
            />                         
        </div>  
      </div>
  )
}

export default Cardapio;