import React, {useContext, useState} from 'react';
import { NavLink } from 'react-router-dom';
import fetchData from "../../global/FetchData";
import './SideBar.scss'
// import logo from '../../assets/images/favicon.ico'
import { AppContext } from '../../global/context' 


function SideBar() { 
    
  //-- recebo os parametros do context    
  const {isSidebarOpen, setIsSidebarOpen, getActiveProcard, token, isMobile} = useContext(AppContext); 
  const [procard, setProcard] = useState();  
  const [config, setConfig] = React.useState({});  
    
  const handleLogout = () => {
    sessionStorage.clear();
    //window.location.pathname = '/#logout';        
    window.location.href = '/';        
    //sessionStorage.removeItem("token");        
  }



  React.useEffect(() => {    
    //-- Fecha a sidebar no primeiro carregamento
    const loadData = async () => {        
        
        // (width < 700) ? closeSidebar() : openSidebar();
        const retorno = await fetchData.getConfig2(token);                
        
        //-- Sessão expirada
        if(retorno.code === 401){            
            sessionStorage.clear();    
            window.location.href = '/';      
        }       
        setConfig(retorno) 
        const active = getActiveProcard()
        setProcard(active)  
    };   
    loadData();  
  }, [token, getActiveProcard]);



  const handleClick = () => {          
    setIsSidebarOpen(!isMobile);      
  }

  
  return(
    <div className={`bg-light border-right sidebar-wrapper ${isSidebarOpen && `open`}`} >
        {/* {console.log('aberto? ' + isSidebarOpen)} */}
        <div className="sidebar-heading navbar navbar-prodabit navbar-dark">
            <span className="navbar-brand" href="#">
                <img src='/assets/images/favicon.ico' width="30" height="30" alt=""/>
                <span>ProPDV.Card</span>
            </span>  
        </div>

        <div className="list-group list-group-flush" id="menu-lateral">
        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>.: RELATÓRIOS PROCARD .:</span>
        </h6>
        
        <ul className="nav flex-column mb-2">    
            {config.show_dash === "1" && 
                <li className="nav-item">
                    <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/'><i className="bi-house" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Dashboard</NavLink>
                </li>            
            }
            <li className="nav-item">
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/alterar_senha'><i className="bi-shield-lock" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Alterar Senha</NavLink>
            </li>            
            <li className="nav-item">
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/vendas'><i className="bi-bag" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Compras Realizadas</NavLink>
            </li>
            <li className="nav-item">
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/consumo'><i className="bi-clipboard" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Produtos Consumidos</NavLink>
            </li>            
        </ul>

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>.: RECARGAS PROCARD .:</span>
        </h6>
        <ul className="nav flex-column mb-2">
            <li className="nav-item">            
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/recargas'><i className="bi-wallet2" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Relatório de Recargas</NavLink>
            </li>
            <li className="nav-item">
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/add-creditos'><i className="bi-credit-card" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Realizar Recarga</NavLink>
            </li> 
        </ul>

        {/* título do cardápio */}
        {(config.show_cardapio === "1" || config.link_cardapio !== "") && 
        <>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>.: CARDÁPIO/PRODUTOS .:</span>
            </h6>
            <ul className="nav flex-column mb-2">
        
            {(config.show_cardapio === "1") && 
                <li className="nav-item">            
                    <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/cardapio'><i className="bi-book" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Cardápio/Produtos</NavLink>
                </li>                        
            }
            {(config.link_cardapio && config.link_cardapio !== "") &&             
                <li className="nav-item"> 
                    <a href={config.link_cardapio} className="nav-link" target="_blank" rel="noreferrer"><i className="bi-journals" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Link Cardápio</a>           
                </li>                        
            }    
            </ul>            
        </>
        }


        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>.: FUNCIONALIDADES BACKEND .:</span>
        </h6>
        <ul className="nav flex-column mb-2">                
            
            {procard && procard.tipo === "SUR" &&
                <li className="nav-item">
                    <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/configuracao'><i className="bi-gear" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Configurações</NavLink>
                </li>  
            }


            <li className="nav-item">
                <NavLink 
                    className='nav-link' 
                    onClick={() => handleLogout()}
                    to='/logout'>
                    <i className="bi-door-open" style={{fontSize: "1.2rem", marginRight:"5px", color: "red"}}></i>                     
                    Sair
                </NavLink>
            </li>            
        </ul>              
        </div>    
    </div>
  )
}

export default SideBar;



