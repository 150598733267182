import React, { useState, useContext } from "react";
import { NavLink } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import fetchData from '../../global/FetchData';
import { AppContext } from '../../global/context' 
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import "./Login.scss";
// import logo_prodabit from '../../assets/images/logo-prodabit-transp.png'
// import bgImage from '../../assets/images/bakground-procard-propdv3.png'

export default function Login({setLoading}) {
  
  const [login, setLogin] = useState({"background_color": 'fafbfc', "card_color": "#eaeaea", "font_color": "#747577"});
  const [senha, setSenha] = useState("");   
  const [estilo, setEstilo] = useState({});   
  const {saveProcards, setActiveProcard} = useContext(AppContext); 
  const {setToken} = useContext(AppContext); 
  const MySwal = withReactContent(Swal);

  React.useEffect(() => {
    const loadAll = async () => {        
        const res = await fetchData.getConfig2(); 
        if(res) setEstilo(res);      
    }
    loadAll();    
  }, []) 
  

  function validateForm() {
    return login.length > 0 && senha.length > 0;
  }

  async function handleSubmit(event) {
    
    event.preventDefault();    
    setLoading(true);
    const data = await fetchData.makeLogin({
        login: login,
        senha: senha,
    });         
    
    if(data.status !== 200){
        MySwal.fire({
            title: 'Oops!',
            html: 'Não foi possível fazer login. Verifique o número e a senha e tente novamente!',
            icon: 'warning'     
        })
        return;
    }
    
    await saveProcards(data.procards);
    await setActiveProcard(data.procards[0].numero);
    setToken(data.access_token);    
    setLoading(false);
    window.location.href = '/';      
    // window.location.href = data.home_page;        
  }  

//   const esqueceuSenhaHandler = () => {
//     window.location.href = '/resetar_senha';                
//   }

  let background_ = {"backgroundColor": estilo.background_color}
  if(estilo.background_img && estilo.background_img !== ""){
    background_ = {...background_, "backgroundSize": "cover",
                                   "backgroundRepeat": "no-repeat",
                                   "backgroundAttachment": "fixed",
                                   "backgroundImage": `url(/assets/images/${estilo.background_img})`}
  }                     
    
  return (
    <div className="limiter" style={background_}>
        <div className="container-login">
            <div className="wrap-login" style={{"backgroundColor": estilo.card_color}}>
                <div className="tilt-image">
                    {/* <img src='/assets/images/img-01.png' alt="IMG"/>  */}                    
                    <img src={`/assets/images/${estilo.logo}`} alt=""/>                      
                    <div className="endereco">
                        <div className="endereco-main" style={{"color": estilo.font_color}}>{(window.location.hostname).replace('procardbr.com.br','')}</div>
                        <div className="endereco-sec" style={{"color": estilo.font_color}}>procardbr.com.br</div>
                    </div>
                </div>                

                <form className="login-form validate-form" onSubmit={handleSubmit}>
                    <span className="login-form-title" style={{"color": estilo.font_color}}>
                        Procard Login
                    </span>
                    <Form.Group size="lg" controlId="login" className="wrap-input validate-input">
                        <Form.Control
                            className="input"
                            autoFocus
                            type="text"
                            name="email" 
                            autoComplete="off"
                            placeholder="ProCard ou Email" 
                            // value="1365"
                            onChange={(e) => setLogin(e.target.value)}                            
                        />
                        <span className="focus-input"></span>
                        <span className="symbol-input">
                            <i className="bi bi-envelope-fill" aria-hidden="true"></i>
                        </span>
                    </Form.Group>                    

                    <Form.Group size="lg" controlId="senha" className="wrap-input validate-input">                        
                        <Form.Control
                            className="input"
                            type="password"
                            placeholder="Senha"                            
                            onChange={(e) => setSenha(e.target.value)}
                        />
                        <span className="focus-input"></span>
                        <span className="symbol-input">
                            <i className="bi bi-lock-fill" aria-hidden="true"></i>
                        </span>
                    </Form.Group>

                    
                    <div className="container-login-form-btn">
                        <button className="login-form-btn" type="submit" disabled={!validateForm()}>
                            Login
                        </button>
                    </div>

                    <div className="text-center mt-2">                           
                        {/* <Link to="/resetar_senha">Esqueceu a Senha?</Link> */}                        
                        {/* <Link className="txt2" href="/resetar_senha" style={{"color": estilo.font_color}} >
                            Esqueceu a Senha?
                        </Link> */}
                        {/* <Link to="/resetar_senha" className="txt2" style={{"color": estilo.font_color}}>Esqueceu a Senha?</Link> */}

                        <NavLink className="txt2" to='/resetar_senha' style={{"color": estilo.font_color}}>Esqueceu a Senha?</NavLink>
              
                    </div>                    
                </form>
            </div>
            <div className="prodabit">                        
                <a href="http://sistemafrentedecaixa.com.br" target={"blank"} >
                    Powered by <b>Prodabit Sistemas</b>
                </a>
            </div>                    
        </div>        
    </div>    
  );
}

