import React, {useContext} from 'react';
import "./Login.scss";
import MySwal from "sweetalert2";
import { AppContext } from '../../global/context' 
import fetchData from '../../global/FetchData';

function AlterarSenha({setLoading, setTitle, firstAccess}) {  

    
    const salt = '2a$10$Cxd46tUjtthH0DeWKeXtQe'
    const { getActiveProcard, token } = useContext(AppContext);
    const [state, setState] = React.useState({    
        atual: '',
        nova: '',
        confirma: ''
    });     

    React.useEffect(() => {      
        setTitle("Alteração de Senha");
        setLoading(false);
    }, [setTitle, setLoading]); 
   
    
    function handleChange(evt) {
        const value = evt.target.value;
        setState({...state, [evt.target.name]: value});    
    }


    function handleLogout() {
        sessionStorage.clear();
        //window.location.pathname = '/#logout';        
        window.location.href = '/';        
        //sessionStorage.removeItem("token");        
    }
    
    
    async function handleSubmit(event) {        
      
        setLoading(true);  
        event.preventDefault();
          
        if(state.nova.length === 0 || state.confirma.length === 0){
            MySwal.fire({title: "Oops!", html: 'Todos os campos devem ser preenchidos.', icon: "warning"}); 
            setLoading(false);  
            return;
        }
        
        //-- Senha nova menor que 4 caracteres
        if(state.nova.length < 4){
            MySwal.fire({title: "Oops!", html: 'Senha deve ter no mínimo 4 dígitos.', icon: "warning"}); 
            setLoading(false);  
            return;
        }

        //-- Senha diferente de confirmação
        if(state.nova !== state.confirma){
            MySwal.fire({title: "Oops!", html: 'Confirmação da senha diferente da senha.', icon: "warning"}); 
            setLoading(false);  
            return;
        }
        
        // hash created previously created upon sign up
        const hashedPassword = state.nova + salt 

        const procard = getActiveProcard();
        const dados = {
            procard_id: procard.id,
            atual: state.atual,
            nova:  hashedPassword,
            user_id: procard.user_id
        }            

        const retorno = await fetchData.changePassword(dados, token);
        if (retorno.status === "success") {            

            MySwal.fire({title: "Tudo certo!", html: retorno.message, icon: "success"}
            ).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {                    
                    state.atual = '';
                    state.nova = '';
                    state.confirma = ''; 
                    procard.first_access = '0';
                    //setActiveProcard(procard.numero);
                    window.location.reload(false);
                    //handleLogout();                                      
                }
            })                          
        }
        else{
            MySwal.fire({title: "Oops!", html: retorno.message, icon: "error"});
        }
        setLoading(false);                
    }      

    
    return(           
    <div className="container-form-change-password">
        <form onSubmit={handleSubmit}>
            <img className="mb-4" src="/assets/images/cadeado.png" alt="" width="72px" />
            
            {firstAccess === '1' && <h5 className="mb-3 fw-normal">Primeiro Acesso. Altere sua senha:</h5>}
            {firstAccess === '0' && <h2 className="mb-3 fw-normal">Entre com os dados:</h2>}

            <div className="form-floating mb-1">
                <input name="atual" type="password" className="form-control" value={state.atual} onChange={handleChange} />
                <label htmlFor="atual">Senha Atual</label>
            </div>
            <div className="form-floating mb-1">
                <input name="nova" type="password" className="form-control" value={state.nova} onChange={handleChange} />
                <label htmlFor="nova">Nova senha</label>
            </div>
            <div className="form-floating mb-1">
                <input name="confirma" type="password" className="form-control" value={state.confirma} onChange={handleChange} />
                <label htmlFor="confirma">Corfirma nova senha</label>
            </div>  
      
            <button className="w-100 btn btn-lg btn-primary mb-1" type="submit">Salvar</button>            
            {firstAccess === '1' && 
                <button className="w-100 btn btn-lg btn-success" type="button" onClick={() => handleLogout()}>Sair</button>                            
            }
        </form>
    </div>  
  )
}

export default AlterarSenha;